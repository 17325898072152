import React from "react"
import Page from '../components/Page'
import Layout from "../components/Layout"
import { graphql } from 'gatsby'
import StoryblokService from '../utils/storyblok-service'
import { PageContext } from "src/utils/context";
 
export default class PageTemplate extends React.Component {
  state = {
    story: this.props.data.story
      ? {
          name: this.props.data.story.name,
          content: JSON.parse(this.props.data.story.content),
          first_published_at: this.props.data.story.first_published_at,
          published_at: this.props.data.story.published_at,
        }
      : {},
  };
 
  async getInitialStory() {
    StoryblokService.setQuery(this.props.location.search)
    let { data: { story } } = await StoryblokService.get(`cdn/stories/${this.props.data.story.full_slug}`)
    return story
  }
 
  async componentDidMount() {
    let story = await this.getInitialStory();
    if (story.content) {
      this.setState({
        story: {
          name: story.name,
          content: story.content,
          first_published_at: story.first_published_at,
          published_at: story.published_at,
        }
      });
    }
    setTimeout(() => StoryblokService.initEditor(this), 200);
  }
 
  // Passing innerContent so we can use it to change the style of the navbar if the first block is Hero
  render() {
    return (
      <PageContext.Provider
        value={{
          name: this.state.story.name,
        }}
      >
        <Layout location={this.props.location} innerContent={this.state.story.content}>
          <Page blok={this.state.story.content} firstPublished={this.state.story.first_published_at} publishedAt={this.state.story.published_at} />
        </Layout>
      </PageContext.Provider>
    )
  }
}

 
export const query = graphql`
  query storyBySlug($fullSlug: String!) {
    story: storyblokEntry(full_slug: { eq: $fullSlug }) {
      name
      content
      full_slug
      uuid
      first_published_at
      published_at
    }
  }
`